<template>
  <div class="inner-step">
    <div class="container">
      <div class="row d-flex justify-content-center">
        <div class="col-sm-12 col-md-10">
          <div class="row mt-2">
            <div class="col-md-6 mb-4">
              <div class="form-field">
                <label for="game-type" v-text="$t('GameType')" />
                <select class="form-control" id="game-type" v-model="booking_details.type" @change="selectType">
                  <option value="" disabled selected hidden v-text="$t('ChooseCategory')" />
                  <option :value="type" v-for="type in types" :key="type" v-text="type" />
                </select>
              </div>
            </div>
            <div class="col-md-6 mb-4">
              <div class="form-field">
                <label for="booking-lang" v-text="$t('Language')" />
                <select
                  class="form-control"
                  id="booking-lang"
                  v-model="booking_details.language"
                  :disabled="!booking_details.type"
                  @change="selectLanguage"
                >
                  <option value="" disabled selected hidden v-text="$t('ChooseLanguage')" />
                  <option :value="language" :key="language" v-for="language in languages">
                    {{ language | languageName }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-12 mb-4">
              <div class="form-field">
                <label for="booking-game" v-text="$t('Game')" />
                <select
                  v-model="booking_details.id"
                  id="booking-game"
                  class="form-control"
                  :disabled="!booking_details.language"
                  @change="selectGame"
                >
                  <option value="" disabled selected hidden v-text="$t('ChooseAGame')" />
                  <option :value="game.id" v-for="game in games" :key="game.id">
                    {{ game.series ? `${game.series}, ${$t('Episode')} ${game.episode}: ${game.title}` : game.title }}
                  </option>
                  <optgroup label=""></optgroup>
                </select>
              </div>
            </div>
            <template v-if="game !== null">
              <template v-if="game.available_instantly">
                <div class="col-md-6 col-lg-5">
                  <div class="form-field">
                    <label for="num-players" v-text="$t('HowManyAreYou')" />
                    <input
                      type="number"
                      id="num-players"
                      class="form-control"
                      :min="game.min_players_per_team"
                      step="1"
                      max="1000"
                      v-model.number="booking_details.players"
                    />
                  </div>
                </div>
                <div class="col-md-6 col-lg-5 mt-md-4">
                  <div
                    class="small mt-2"
                    v-text="$t('HowManyAreYouNote', { min: game.min_players_per_team, max: game.max_players_per_team })"
                  />
                </div>
                <div class="col-12 mt-5 text-center text-md-right">
                  <div class="paragraph-1">
                    <BookingPrice :booking_details="booking_details" />
                  </div>
                </div>
              </template>
              <template v-else>
                <div class="col-lg-7">
                  <div class="app-calendar">
                    <label>{{ $t('WhenDoYouWantToPlay') }}</label>
                    <v-date-picker
                      :locale="$i18n.locale"
                      :firstDayOfWeek="2"
                      :min-date="tomorrow"
                      v-model="booking_details.date"
                      color="red"
                      mode="single"
                      is-inline
                      is-expanded
                    />
                  </div>
                </div>
                <div class="col-lg-5 d-lg-flex flex-lg-column justify-content-lg-between">
                  <div class="row mt-4 mt-lg-0">
                    <div class="col-sm-6 col-lg-12">
                      <div class="form-field mb-4">
                        <label for="booking-time" v-text="$t('AndWhichTime')" />
                        <select id="booking-time" class="form-control" v-model="booking_details.time">
                          <option :value="null" disabled selected hidden v-text="$t('Choose')" />
                          <option :value="time" v-for="(time, id) in times" :key="id" v-text="time" />
                        </select>
                      </div>
                    </div>
                    <div class="col-sm-6 col-lg-12 mb-4">
                      <div class="form-field">
                        <label for="num-players-2" v-text="$t('HowManyAreYou')" />
                        <input
                          type="number"
                          id="num-players-2"
                          class="form-control"
                          :min="game.min_players_per_team"
                          step="1"
                          max="1000"
                          v-model.number="booking_details.players"
                        />
                        <div
                          class="small mt-2"
                          v-text="$t('HowManyAreYouNote', { min: game.min_players_per_team, max: game.max_players_per_team })"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 mt-3 mt-lg-0">
                      <div class="paragraph-1 text-center text-lg-left">
                        <BookingPrice :booking_details="booking_details" />
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <div class="col-12" v-if="booking_details.players">
                <div
                  class="box is-danger is-small mt-3"
                  v-if="booking_details.players < game.min_players_per_team"
                  v-text="$t('ThereShouldBeAtLeastNPlayers', { min: game.min_players_per_team })"
                />
                <div
                  class="box is-info is-small  mt-3"
                  v-if="booking_details.teams > 1"
                  v-text="$t('TheNumberOfPeopleExceeds', { count: booking_details.teams })"
                />
              </div>
            </template>
          </div>
          <div class="row mt-4">
            <div class="col-12">
              <footer class="footer-booking pt-3 pt-lg-4">
                <div class="row d-flex justify-content-end">
                  <div class="col-sm-6 col-md-5 col-lg-4">
                    <button @click.prevent="next" class="btn is-accent" :disabled="disallowNext">
                      {{ $t('Next') }}
                      <i class="fas fa-angle-right"></i>
                    </button>
                  </div>
                </div>
              </footer>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { uniq, keys, has } from 'lodash';
import BookingPrice from '../components/BookingPrice';

const questNames = {
  cityquest: 'CityQuest',
  homequest: 'HomeQuest',
  officequest: 'OfficeQuest',
};

const questLanguages = {
  sv: 'Svenska',
  en: 'English',
  ru: 'Русский',
};

export default {
  name: 'ChooseGame',
  components: {
    BookingPrice,
  },
  data() {
    return {
      booking_details: {
        type: '',
        language: '',
        id: '',
        title: '',
        players: '',
        teams: '',
        date: null,
        time: null,
      },
    };
  },
  watch: {
    'game'() {
      if (this.game) {
        if (this.game.available_instantly) {
          this.booking_details.date = null;
          this.booking_details.time = null;
        }
        this.booking_details.title = this.game.series
          ? `${this.game.series}, ${this.$t('Episode')} ${this.game.episode}: ${this.game.title}`
          : this.game.title;
      }
    },
    'booking_details.players'(value) {
      value = value === '' ? 0 : value;
      if (this.game !== null) {
        const { pricing_unit,  max_players_per_team,  price_per_unit } = this.game;
        this.booking_details.teams = Math.ceil(
          parseInt(value) / parseInt(max_players_per_team)
        );
        let amount = '';
        switch (pricing_unit) {
          case 'player':
            amount = parseInt(value) * parseInt(price_per_unit.amount);
            break;
          case 'team':
            amount =
              this.booking_details.teams * parseInt(price_per_unit.amount);
            break;
        }
        if (this.discount.is_active) {
          const data_for_discount = {
            game_script_id: this.booking_details.id,
            num_players: parseInt(this.booking_details.players),
            num_teams: parseInt(this.booking_details.teams),
            discount_code: this.discount.discount_code,
          };
          this.$store.dispatch('fetch_discount', data_for_discount);
        } else {
          this.$store.dispatch('save_booking_amount', {
            amount,
            currency: price_per_unit.currency,
          });
        }
      }
    },
  },
  computed: {
    ...mapGetters([
      'loading',
      'bookable_games',
      'booked_game',
      'booking_amount',
      'discount',
    ]),
    types() {
      return uniq(this.bookable_games.map(({ game_type }) => game_type));
    },
    languages() {
      if (this.booking_details.type) {
        return uniq(
          this.bookable_games
            .filter(({ game_type }) => this.booking_details.type === game_type)
            .map(({ language }) => language)
        );
      }
      return [];
    },
    games() {
      if (this.booking_details.type && this.booking_details.language) {
        return this.bookable_games
          .filter(({ game_type }) => this.booking_details.type === game_type)
          .filter(({ language }) => this.booking_details.language === language);
      }
      return [];
    },
    game() {
      if (this.booking_details.id) {
        return this.bookable_games.find(({ id }) => Number(this.booking_details.id) === id);
      }
      return null;
    },
    tomorrow() {
      const tomorrow = new Date();
      tomorrow.setDate(tomorrow.getDate() + 1);
      return tomorrow;
    },
    times() {
      const start = 10;
      const end = 20;
      let intervals = [];
      for (let i = start; i <= end; i += 1) {
        intervals.push(i);
      }
      intervals = intervals
        .map((time) => `${time}:00|${time}:30|`)
        .join('')
        .split('|')
        .filter((t) => t);
      intervals.splice(-1, 1);
      return intervals;
    },
    disallowNext() {
      const { time, date, players } = this.booking_details;
      if (this.game === null) {
        return true;
      }
      if (this.game.available_instantly === false && (!!time === false || !!date === false)) {
        return true;
      }
      return players < this.game.min_players_per_team;

    },
  },
  methods: {
    selectType() {
      this.booking_details.language = '';
      this.booking_details.id = '';
    },
    selectLanguage() {
      this.booking_details.id = '';
    },
    selectGame() {
      this.$store.dispatch('clear_discount');
      if (String(this.booking_details.id) !== this.$route.query.id ) {
        this.$router.push({ query: { id: this.booking_details.id }});
      }
      this.$store.dispatch('save_booking_amount', {
        amount: 0,
        currency: this.game.price_per_unit.currency,
      });
      this.booking_details.players = '';
    },
    next() {
      this.$store.dispatch('save_booking_details', this.booking_details);
      this.$store.dispatch('save_booked_game', this.game);
      this.$router.push({ name: 'ConfirmBooking', query: { id: this.$route.query.id } });
      this.$gtm.trackEvent({
        event: 'begin_checkout',
      });
    },
  },
  async mounted() {
    this.$gtm.trackEvent({
      event: 'first_visit_booking',
    });
    this.$emit('onChangeStep', 1);
    await this.$store.dispatch('fetch_bookable_games');
    if (this.$route.query.id) {
      const game = this.bookable_games.find(({ id }) => Number(this.$route.query.id) === id);
      if (game) {
        this.booking_details.type = game.game_type;
        this.booking_details.language = game.language;
        this.booking_details.id = game.id;
        await this.$store.dispatch('save_booking_amount', {
          amount: 0,
          currency: game.price_per_unit.currency,
        });
      }
    } else {
      if (this.$route.query.type && has(questNames, this.$route.query.type)) {
        this.booking_details.type = questNames[this.$route.query.type];
      }
      if (this.$route.query.language && this.$route.query.type && has(questLanguages, this.$route.query.language)) {
        this.booking_details.language = this.$route.query.language;
      }
    }
    if (this.$store.getters.booking_details !== null) {
      keys(this.$store.getters.booking_details).forEach((key) => {
        this.$set(this.booking_details, key, this.$store.getters.booking_details[key]);
      })
    }
  },
};
</script>

<style scoped lang="scss">
@import '../styles/utilities/utilities';

.price {
  font-size: 2em;
  font-weight: 700;
}

.calendar-wrapper {
  > * {
    margin: 25px auto;
  }
}

.inner-step {
  background-image: url('../assets/images/forms-1.png'),
    url('../assets/images/forms-2.png');
  background-repeat: no-repeat;
  background-position: 30px bottom, calc(100% - 30px) top;
  background-size: 220px, 250px;
  @include media(md) {
    background-size: 300px, 370px;
  }
}
</style>
